//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';
import { UserModel } from '@/modules/auth/user-model';
import {mapGetters } from 'vuex';
import Roles from '@/security/roles';
const { fields } = UserModel;

export default {
  name: 'app-reservation-list-table',

  data() {
    return {
      selectedId:'',
      record: null,
      dialogVisible:false ,
      Pagination: {
        rowsPerPage: 5
        
      },
      
      columns: [
        {
          name: 'clientName',
          align: 'center',
          label: i18n('tables.reservation.clientName'),
          field: 'clientName',
        },        
        {
          name: 'tripName',
          align: 'center',
          label: i18n('tables.reservation.tripName'),
          field: 'tripName',
        },       
         {
          name: 'tourGuide',
          align: 'center',
          label: i18n('tables.reservation.tourGuide'),
          field: 'tourGuide',
        },
        {
          name: 'branch',
          align: 'center',
          label: i18n('tables.reservation.branch'),
          field: 'branch',
        },
        {
          name: 'paymentMethod',
          align: 'center',
          label: i18n('tables.reservation.paymentMethod'),
          field: 'paymentMethod',
        },



      ],
      rows: [
        {
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
                  clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },{
          clientName: 'Ahmed',
          tripName: 'Makkah Trip',
          tourGuide:'+966123456',
          branch:'Makkah',
          paymentMethod: 'PayPal',
        },
      ]
    };
  },

  // mounted hook call two function when page loading first is doFilter to diplay table upon to that filteration 
  // 

//   mounted() {
//     // console.log('tour guide')
//     this.doFilter();
//     this.doMountTable(this.$refs.table);
//   },





  computed: {
    ...mapGetters({
    //   rows: 'tourGuide/list/rows',
    //   count: 'tourGuide/list/count',
    //   loading: 'tourGuide/list/loading',
    //   pagination: 'tourGuide/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    fields() {
      return fields;
    },

  },
  created() {
    // console.log(this.rows);
  },

  methods: {
    // ...mapActions({
    //   doChangeSort: 'tourGuide/list/doChangeSort',
    //   doChangePaginationCurrentPage:
    //     'tourGuide/list/doChangePaginationCurrentPage',
    //   doChangePaginationPageSize:
    //     'tourGuide/list/doChangePaginationPageSize',
    //   doMountTable: 'tourGuide/list/doMountTable',
    //   doFetch: 'tourGuide/list/doFetch',
    // }),
    // mapAction call to this functions from store { doFetch && doChangeSort && doChangePaginationCurrentPage && doChangePaginationPageSize && doMountTable }





    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    // presenter function display data from model to fields 

    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },

    // doFilter function first check validation then clear it finally call doFetch and pass filter to it 

    async doFilter() {
      // try {
      //   await this.$refs.form.validate();
      //   this.$refs.form.clearValidate();
      // } catch (error) {
      //   return;
      // }
      const filter = {};
      return this.doFetch({
        filter,
      });
    },


  // i18n for localization 

    // i18n(code) {
    //   return i18n(code);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
