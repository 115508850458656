//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ReservationListTable from '@/modules/reservation/components/reservation-list-table.vue';

export default {
  name: 'app-reservation-list-page',

  components: {
    [ReservationListTable.name]: ReservationListTable,
  },
  data() {
    return {
      searchInput: '',
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
